import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
// import "./PageTitle.scss";
import { ChevronRight } from "feather-icons-react";

export default function PageTitle({ mainTitle, breadcrumbItems }) {
  const items = [{ name: "Home", path: "/home" }, ...breadcrumbItems];
  return (
    <div className="breadcrum">
      <div className="page-path mb-2">
        {items.length > 0 ? (
          <Breadcrumb>
            {items.map((item, i) => (
              <Breadcrumb.Item key={i}>
                <Link className="font-4" to={item.path}>
                  {item.name}
                </Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        ) : (
          ""
        )}
      </div>
      <h1 className="title font-weight-medium">{mainTitle}</h1>
    </div>
  );
}
