import React, { useEffect } from "react";
import { Layout } from "antd";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router
import HeaderNav from "./Header";
import FooterNav from "./Footer";
import SiderMenu from "../Components/SiderMenu/SiderMenu";
import MenuData from "../Components/SiderMenu/MenuData";

const { Content } = Layout;

const Layouts = (props) => {
  const location = useLocation();
  const isHome = location.pathname === "/"; // Assuming the home page URL is "/"

  useEffect(() => {
    window.scrollTo(0, 0);

    // Add or remove "home" class from body element
    if (isHome) {
      document.body.classList.add("home");
    } else {
      document.body.classList.remove("home");
    }

    // Clean up the class on unmount
    return () => {
      document.body.classList.remove("home");
    };
  }, [location, isHome]);

  return (
    <Layout style={{ minHeight: "100vh" }} className="site-layout">
      {props.location.pathname === "/" ? (
        ""
      ) : (
        <>
          <SiderMenu
            collapsible
            collapsed
            onCollapse
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={(e) => {
              const selectedItem = MenuData().find(
                (item) => item.key === e.key
              );
              if (selectedItem && selectedItem.onClick) {
                selectedItem.onClick();
              }
            }}
            items={MenuData()}
            width={200}
            collapsedWidth={50}
          />
          {/* <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <div className="logo-vertical d-flex align-items-center justify-content-center">
              <img src={AssetPath.brand.sandozFavicon} alt="Sandoz" />
            </div>
            <Menu
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={items}
            />
          </Sider> */}
        </>
      )}
      <Layout>
        {props.location.pathname === "/" ? "" : <HeaderNav {...props} />}
        <Content>{props.children}</Content>
        <FooterNav />
      </Layout>
    </Layout>
  );
};

export default Layouts;
