import React from "react";
import { Copy, Edit, Loader, MoreVertical } from "feather-icons-react";
import { Dropdown, Menu, message, Table } from "antd";
import { useHistory } from "react-router-dom";
import apiClient from "../Actions/apiClient";
import DeleteJob from "./DeleteJob";
import "./Home.scss";
import ShowTable from "../Components/ShowTable/ShowTable";
import {
  Calendar,
  Clock,
  Folder,
} from "feather-icons-react/build/IconComponents";

const JobsList = ({ jobs, onDelete, onJobClone }) => {
  const history = useHistory();
  const handleEdit = (jobId) => {
    history.push(`/update-job/${jobId}`);
  };
  const handleJobClick = (jobId) => {
    history.push(`/payroll-process/${jobId}`);
  };
  const handleClone = async (job) => {
    try {
      const { data } = await apiClient.post("/job/job", {
        jobTitle: `Copy of ${job?.JobTitle}`,
        userId: job?.JobUserID,
        DC3TaskID: JSON.stringify(job?.DC3TaskID),
      });
      if (data?.success) {
        message.success("Job cloned successfully");
        onJobClone();
      } else {
        message.error("Failed to clone job");
      }
    } catch (error) {
      message.error("Error cloning job");
      console.error(error);
    }
  };
  const dropdownItems = (job) => [
    {
      label: (
        <span
          className="d-flex align-items-center text-gray"
          onClick={() => handleEdit(job?.JobTaskID)}
        >
          <Edit size={16} className="me-2" />
          Edit
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span
          className="d-flex align-items-center text-gray"
          onClick={() => handleClone(job)}
        >
          <Copy size={16} className="me-2" />
          Clone
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span className="d-flex align-items-center text-gray">
          <Loader size={16} className="me-2" />
          Execute
        </span>
      ),
      key: "2",
    },
    {
      label: (
        <DeleteJob
          jobId={job?.JobTaskID}
          jobTitle={job?.JobTitle}
          onDelete={onDelete}
        />
      ),
      key: "3",
    },
  ];
  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };
  const getStatusClassName = (status) => {
    switch (status) {
      case "NEW":
        return "released";
      case "IN PROGRESS":
      case "Processing":
        return "executing";
      case "COMPLETED":
        return "completed";
      case "Error":
        return "error";
      default:
        return "";
    }
  };
  const columns = [
    {
      title: "Job Title",
      dataIndex: "JobTitle",
      key: "JobTitle",
      render: (text, record) =>
        typeof text === "string" ? (
          <>
            <Folder size={17} className="text-gray me-2" />
            <span
              // className="job-title-link"
              onClick={() => handleJobClick(record.JobTaskID)}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {text}
            </span>
          </>
        ) : null,
    },
    {
      title: "Creation Date",
      dataIndex: "JobCreationDateTime",
      key: "JobCreationDateTime",
      render: (text) => {
        const date = new Date(text);
        return !isNaN(date.getTime()) ? (
          <div className="text-gray">
            <Calendar size={16} className="me-2" /> {date.toLocaleDateString()}
          </div>
        ) : null;
      },
    },
    {
      title: "Creation Time",
      dataIndex: "JobCreationDateTime",
      key: "JobCreationDateTimeTime",
      render: (text) => {
        const date = new Date(text);
        return !isNaN(date.getTime()) ? (
          <div className="text-gray">
            <Clock size={16} className="me-2" />
            {date.toLocaleTimeString()}
          </div>
        ) : null;
      },
    },
    {
      title: "Status",
      dataIndex: "JobTaskStatusName",
      render: (text, job) => (
        <div className="status d-flex align-items-center justify-content-start">
          <div
            className={`status-circle rounded-circle me-2 ${getStatusClassName(
              job.JobTaskStatusName
            )}`}
          >
            {/* <span>{text}</span> */}
          </div>
          <span className="text-capitalize">{job?.JobTaskStatusName}</span>
        </div>
      ),
    },
    {
      title: "User",
      dataIndex: "UserName",
      render: (text, job) => (
        <span className="cursor-pointer profile text-white bg-secondary rounded-circle d-flex align-items-center justify-content-center">
          {getInitial(job.User.UserName)}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          overlay={<Menu items={dropdownItems(record)} />}
          trigger={["click"]}
        >
          <span className="link rounded">
            <MoreVertical size={18} className="cursor-pointer" />
          </span>
        </Dropdown>
      ),
    },
  ];
  return (
    <div className="jobs-list">
      {jobs && jobs.length > 0 ? (
        <ShowTable columns={columns} dataSource={jobs} showSorterTooltip />
      ) : (
        <p>No recent jobs found</p>
      )}
    </div>
  );
};

export default JobsList;
