import React from "react";
import {
  Folder,
  MoreVertical,
  Calendar,
  Clock,
  Copy,
  Edit,
  Loader,
} from "feather-icons-react";
import { Col, Dropdown, Menu, Row, message } from "antd";
import "./Home.scss";
import DeleteJob from "./DeleteJob";
import { useHistory } from "react-router-dom";
import apiClient from "../Actions/apiClient";

export default function JobsGrid({ jobs, onDelete, onJobClone }) {
  const history = useHistory();
  const handleEdit = (jobId) => {
    history.push(`/update-job/${jobId}`);
  };
  const handleJobClick = (jobId) => {
    history.push(`/payroll-process/${jobId}`);
  };
  const handleClone = async (job) => {
    try {
      apiClient
        .post("/job/job", {
          jobTitle: `Copy of ${job?.JobTitle}`,
          userId: job?.JobUserID,
          DC3TaskID: JSON.stringify(job?.DC3TaskID),
        })
        .then(({ data }) => {
          if (data?.success) {
            message.success("Job cloned successfully");
            onJobClone();
            // Optionally, refresh the job list or handle success state
          } else {
            message.error("Failed to clone job");
          }
        });
    } catch (error) {
      message.error("Error cloning job");
      console.error(error);
    }
  };
  const dropdownItems = (job) => [
    {
      label: (
        <span
          className="d-flex align-items-center text-gray"
          onClick={() => handleEdit(job?.JobTaskID)}
        >
          <Edit size={16} className="me-2" />
          Edit
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span
          className="d-flex align-items-center text-gray"
          onClick={() => handleClone(job)}
        >
          <Copy size={16} className="me-2" />
          Clone
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span className="d-flex align-items-center text-gray">
          <Loader size={16} className="me-2" />
          Execute
        </span>
      ),
      key: "2",
    },
    {
      label: (
        <DeleteJob
          jobId={job?.JobTaskID}
          jobTitle={job?.JobTitle}
          onDelete={onDelete}
        />
      ),
      key: "3",
    },
  ];
  const getStatusClassName = (status) => {
    switch (status) {
      case "NEW":
        return "released"; // Class for NEW status
      case "IN PROGRESS":
        return "executing "; // Class for IN PROGRESS status
      case "Processing":
        return "executing "; // Class for IN PROGRESS status
      case "COMPLETED":
        return "completed"; // Class for COMPLETED status
      case "Error":
        return "error"; // Class for COMPLETED status
      default:
        return ""; // Default class
    }
  };
  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <div className="jobs-grid">
      <Row
        gutter={[
          { xs: 0, sm: 12, md: 12, lg: 12 },
          { xs: 0, sm: 12, md: 12, lg: 12 },
        ]}
        align="left"
        justify="left"
      >
        {jobs && jobs?.length > 0 ? (
          jobs?.map((job, i) => (
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              key={i}
            >
              <div className="automated-jobs card position-relative border">
                <div className="title-sec d-flex align-items-start justify-content-between">
                  <div className="icon">
                    <div className="folder bg-gray rounded d-flex align-items-center justify-content-center">
                      <Folder size={19} className="text-primary" />
                    </div>
                  </div>
                  <div className="main-title">
                    <div
                      className="font-4 text-ellipse1"
                      onClick={() => handleJobClick(job?.JobTaskID)}
                      style={{
                        cursor: "pointer", // Change cursor to pointer on hover
                        textDecoration: "underline", // Optional: add underline on hover for better visual feedback
                      }}
                    >
                      {job?.JobTitle}
                    </div>
                    <div className="date-time font-6 d-flex flex-column align-items-left text-gray">
                      <span className="date d-flex align-items-center me-3">
                        <Calendar size={15} className="me-2 text-primary" />
                        {new Date(
                          job?.JobCreationDateTime
                        ).toLocaleDateString()}
                      </span>
                      <span className="time d-flex align-items-center">
                        <Clock size={15} className="me-2 text-primary" />
                        {new Date(
                          job?.JobCreationDateTime
                        ).toLocaleTimeString()}
                      </span>
                    </div>
                  </div>
                  <div className="more">
                    <Dropdown
                      overlay={<Menu items={dropdownItems(job)} />}
                      trigger={["click"]}
                    >
                      <span className="link rounded">
                        <MoreVertical size={18} className="cursor-pointer" />
                      </span>
                    </Dropdown>
                  </div>
                </div>
                <div className="user-status mt-2 d-flex align-items-center justify-content-between">
                  <div className="users">
                    <span className="cursor-pointer profile text-white bg-secondary rounded-circle d-flex align-items-center justify-content-center">
                      {getInitial(job?.User?.UserName)}
                    </span>
                  </div>
                  <div className="status font-6 d-flex align-items-center justify-content-start">
                    <div
                      className={`status-circle rounded-circle me-2 ${getStatusClassName(
                        job?.JobTaskStatusName
                      )}`}
                    />
                    <span className="text-capitalize">
                      {job?.JobTaskStatusName}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          ))
        ) : (
          <p className="text-center">No recent jobs found</p>
        )}
      </Row>
    </div>
  );
}
