import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import apiClient from "../../Actions/apiClient";
import TaskList from "./TasksList"; // Import the TaskList component
import { Tabs } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";

export default function TaskHome() {
  const history = useHistory();
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const { data } = await apiClient.get("/task/dc3/tasks"); // Adjust the endpoint as needed
      setTasks(data);
      setFilteredTasks(data); // Set initial filtered tasks to all tasks
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredTasks(tasks);
    } else {
      const filtered = tasks.filter((task) => {
        const description = task.DCTaskDescription || "";
        const country = task.Country || "";
        const projectName = task.DC3ProjectName || "";

        return (
          description.toLowerCase().includes(value.toLowerCase()) ||
          country.toLowerCase().includes(value.toLowerCase()) ||
          projectName.toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilteredTasks(filtered);
    }
  };

  const onDelete = () => {
    fetchTasks(); // Refresh tasks after deletion
  };

  return (
    <>
      <section className="space-around create-tasks">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="title">Tasks</h3>
          <PageTitle
            breadcrumbItems={[
              {
                name: "Tasks",
                path: "/task-home",
              },
            ]}
          />
        </div>
        {/* <div style={{ textAlign: "right" }}>
            <button
              className="ant-btn ant-btn-primary btn-lg"
              type="button"
              onClick={() => history.push("/create-task")}
            >
              Create New Task
            </button>
          </div> */}

        <div className="all-tasks-section">
          <Tabs
            defaultActiveKey="all-tasks"
            tabBarExtraContent={{
              right: (
                <div className="global-search ">
                  <input
                    type="text"
                    placeholder="Search all tasks"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              ),
            }}
          >
            <Tabs.TabPane tab="All Tasks" key="all-tasks">
              <TaskList
                tasks={filteredTasks}
                onDelete={onDelete}
                loading={loading}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </section>
    </>
  );
}
