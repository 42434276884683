import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import apiClient from "../../Actions/apiClient";
import { useHistory } from "react-router-dom";
import { ArrowUpRight } from "feather-icons-react";
import { message } from "antd";
import "./CreateJob.scss";

// Validation schema
const validationSchema = Yup.object().shape({
  jobTitle: Yup.string().required("Job title is required"),
  DC3TaskID: Yup.string().required("Select a payroll task"),
});

export default function CreateJobForm() {
  const history = useHistory();
  const [tasks, setTasks] = useState([]);
  const [getUserId, setUserId] = useState(null);
  const createNewJob = async (values, { setSubmitting, setStatus }) => {
    const payload = {
      ...values,
      userId: getUserId,
    };

    try {
      const { data } = await apiClient.post("/job/job", payload);
      if (data?.success) {
        message.success("Job created successfully!");
        setTimeout(() => {
          history.push({
            pathname: `/payroll-process/${data.JobTaskID}`,
            state: { getUserId },
          });
        }, 1200);
      } else {
        message.error("Failed to create job:");
        setStatus({ success: false, message: "Failed to create job" });
      }
    } catch (error) {
      message.error("Failed to create job:", error);
      console.error("Failed to create job:", error);
      setStatus({ success: false, message: "Failed to create job" });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await apiClient.get("/task/dc3/tasks"); // Adjust the endpoint as needed
        setTasks(response.data);
      } catch (error) {
        console.error("Failed to fetch DC3 tasks:", error);
      }
    };
    const userData = JSON.parse(
      sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")
    );
    if (userData === null) {
      history.push("/");
      return;
    }
    const UserID = userData?.UserID;
    setUserId(UserID);
    fetchTasks();
  }, []);

  return (
    <div className="card p-5 border mt-3">
      <Formik
        initialValues={{
          jobTitle: "",
          DC3TaskID: "",
        }}
        validationSchema={validationSchema}
        onSubmit={createNewJob}
      >
        {({ values, errors, touched, isSubmitting, status }) => (
          <Form>
            <div className="d-flex flex-column  align-items-center mb-3">
              <div className="field  w-100">
                <Field
                  type="text"
                  name="jobTitle"
                  placeholder="Enter a new job title *"
                  className="form-control"
                />
                {touched.jobTitle && errors.jobTitle ? (
                  <div className="text-danger">{errors.jobTitle}</div>
                ) : null}
                <span className="d-block mt-2 font-5 text-gray font-weight-normal  mb-2">
                  Note: Based on the title folder name will be created
                </span>
              </div>
              <div className="field w-100 mt-2">
                <Field as="select" name="DC3TaskID" className="form-control">
                  <option value="" disabled>
                    Select Payroll*
                  </option>
                  {tasks.map((task) => (
                    <option key={task.DC3TaskID} value={task.DC3TaskID}>
                      {task.DCTaskDescription}
                    </option>
                  ))}
                </Field>
                {touched.DC3TaskID && errors.DC3TaskID ? (
                  <div className="text-danger">{errors.DC3TaskID}</div>
                ) : null}
              </div>
            </div>

            <div className="mt-4">
              <button
                className="ant-btn ant-btn-primary btn-lg"
                type="submit"
                label="Create Job"
                disabled={isSubmitting}
              >
                Create Job
                <ArrowUpRight size={16} className="ms-2" />
              </button>
              {status && status.message && (
                <div
                  className={`text-${
                    status.success ? "success" : "danger"
                  } mt-2`}
                >
                  {status.message}
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
