import React from "react";
import { Table } from "antd";
import "./ShowTable.scss";

function ShowTable({ columns, dataSource, className, showSorterTooltip,onChange }) {

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={{ pageSize: 10 }}
      className={`custom-table ${className}`}
      showSorterTooltip={showSorterTooltip}
      onChange={onChange}
    />
  );
}

export default ShowTable;
