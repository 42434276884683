import sandoz from "../assets/images/brand/sandoz.svg";
import sandozFavicon from "../assets/images/brand/favicon.svg";
import excel from "../assets/images/icons/excel.svg";
import uploadFileImage from "../assets/images/icons/upload.png";

// images
import fileUpload from "../assets/images/input-files.svg";

// wave
import wave from "../assets/images/wave.svg";

const AssetPath = {
  brand: {
    sandoz,
    sandozFavicon,
  },
  icons: {
    excel,
    uploadFileImage,
  },
  images: {
    fileUpload,
  },
  shape: {
    wave,
  },
};

export default AssetPath;
