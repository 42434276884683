import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import AsyncRoute from "./AsyncRoute";
import PageNotFound from "../screens/PageNotFound/PageNotFound";
import AuthenticatedLayout from "../Layout/AuthenticatedLayout";
import { getUserByToken } from "../Actions/UserAction";

const getCommonRoutes = (url) => [
  {
    path: `${url}/contactus`,
    screen: lazy(() => import("../screens/Admin/Contactus")),
  },
];

class AuthenticatedRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: !props.userInfo,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")) {
      this.props
        .dispatch(
          getUserByToken(sessionStorage.getItem("DC3_FILE_AUTOMATION_USER"))
        )
        .then(() => {
          this.setState({ loader: false });
        });
    }
  }

  render() {
    const {
      match: { url },
    } = this.props;

    const currentUser = sessionStorage.getItem("DC3_FILE_AUTOMATION_USER");

    if (!currentUser) {
      return <Redirect to="/" />;
    }

    if (this.state.loader) {
      return <div>Loading..</div>;
    }

    return (
      <Suspense fallback={<center>Loading..</center>}>
        <AuthenticatedLayout {...this.props}>
          <Switch>
            {getCommonRoutes(url).map(({ path, access, ...restProps }) => (
              <Route
                key={path}
                exact
                path={path}
                render={(props) => <AsyncRoute {...props} {...restProps} />}
              />
            ))}

            <Route component={PageNotFound} />
          </Switch>
        </AuthenticatedLayout>
      </Suspense>
    );
  }
}

export default connect()(AuthenticatedRoutes);
