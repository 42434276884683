import { Info } from "feather-icons-react";
import AssetPath from "../../AssetPath/AssetPath";
import ButtonBox from "../ButtonBox/ButtonBox";
import { Download } from "feather-icons-react/build/IconComponents";

export default function FileDownload({ fileName, fileSize, onClick, excel }) {
  return (
    <div className="file-download-card d-flex align-items-center justify-content-between">
      <div className="icon-desc d-flex align-items-center ">
        {excel ? (
          <div className="icon bg-sky-blue rounded me-3">
            <img src={AssetPath.icons.excel} alt="Download file" />
          </div>
        ) : (
          <div className="icon bg-sky-blue rounded me-3">
            <Info size={16} color="#107C41" />
          </div>
        )}
        <div className="title-desc">
          <h6 className="font-weight-medium">{fileName}</h6>
          <span className="text-gray d-block">{fileSize}</span>
        </div>
      </div>{" "}
      <ButtonBox type="default" onClick={onClick} variant="outlined">
        <Download size={17} />
      </ButtonBox>
    </div>
  );
}
