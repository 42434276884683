import React from "react";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  AlertCircle,
  AlignLeft,
  FileText,
  PieChart,
} from "feather-icons-react/build/IconComponents";

function getItem(label, key, icon, children, onClick) {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  };
}

const MenuData = () => {
  const history = useHistory();

  return [
    getItem("Manage Jobs", "jobs1", <PieChart size={16} />, [
      getItem("Create Jobs", "1", null, null, () =>
        history.push("/create-job")
      ),
      getItem("View All Jobs", "2", null, null, () => history.push("/home")),
    ]),
    getItem("Manage Tasks", "tasks1", <AlignLeft size={16} />, [
      getItem("Create Tasks", "3", null, null, () =>
        history.push("/create-task")
      ),
      getItem("View All Tasks", "4", null, null, () =>
        history.push("/task-home")
      ),
    ]),
    getItem("View Tasks", "5", <FileText size={16} />, null, () =>
      history.push("/task-home")
    ),
    // getItem("Create Jobs", "2", <DesktopOutlined />, null, () =>
    //   history.push("/create-job")
    // ),
    getItem("View Logs", "6", <AlertCircle size={16} />, null, () =>
      history.push("/logs")
    ),
    // getItem("User", "sub1", <UserOutlined />, [
    //   getItem("Tom", "3", null, null, () => history.push("/user/tom")),
    //   getItem("Bill", "4", null, null, () => history.push("/user/bill")),
    //   getItem("Alex", "5", null, null, () => history.push("/user/alex")),
    // ]),
  ];
};

export default MenuData;
