import React, { useState, useEffect } from "react";
import "./PayrollProcess.scss";
import { Col, Row, message } from "antd";
import Sidebar from "../../Layout/Sidebar";
import PageTitle from "../../Components/PageTitle/PageTitle";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import { X, Loader } from "feather-icons-react/";
import FileDownload from "../../Components/FileDownload/FileDownload";
import MultiFileUpload from "./MultiFileUpload";
import { useParams, useLocation, useHistory } from "react-router-dom";
import apiClient from "../../Actions/apiClient";
import AppConfig from "../../config";

export default function PayrollProcess() {
  const history = useHistory();
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [fileDetails, setFileDetails] = useState({
    inputFiles: [],
    outputFiles: [],
    errorFiles: [],
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const location = useLocation();
  const { state } = location;
  const { UserID } = JSON.parse(
    sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")
  );
  const getUserId = UserID;
  const viewErrorFile = (id, jobTitle, DCTaskDescription) => {
    history.push({
      pathname: "/logs",
      state: {
        id,
        userId: getUserId,
        jobTitle,
        DCTaskDescription,
      },
    });
  };
  useEffect(() => {
    fetchJobDetails(id);
  }, []);

  const fetchJobDetails = async (jobId) => {
    //setLoading(true);
    try {
      const response = await apiClient.get(`/job/job/${jobId}`);
      if (response?.data?.success) {
        setJobDetails(response?.data?.jobDetails);
        // Set default task description if tasks are available
        const defaultTask = response?.data?.jobDetails?.JobTaskID;
        if (defaultTask) {
          setSelectedTask(defaultTask);
          // getFileDataById(defaultTask);
        }
        const fileLocation =
          response?.data?.jobDetails.FileLocations &&
          response?.data?.jobDetails.FileLocations[0];
        if (fileLocation) {
          setFileDetails({
            inputFiles: fileLocation.InputFiles,
            outputFiles: fileLocation.OutputFiles,
            errorFiles: fileLocation.ErrorLogs,
          });
        } else {
          // Handle the case where fileLocation is undefined or empty
          setFileDetails({
            inputFiles: [],
            outputFiles: [],
            errorFiles: [],
          });
        }
      }
    } catch (error) {
      console.error("Failed to fetch job details:", error);
    } finally {
      setLoading(false);
    }
  };
  // const getFileDataById = async (id) => {
  //   try {
  //     const response = await apiClient.get(
  //       `/file/file/${id}?userID=${getUserId}`
  //     );
  //     if (response?.data?.status) {
  //       console.log("current response", response?.data?.data);
  //       const taskDetails = response?.data; // Assuming the data is an array with one object
  //       console.log("fileData", taskDetails);

  //       const fileLocation = taskDetails.data && taskDetails.data[0];

  //       if (fileLocation) {
  //         setFileDetails({
  //           inputFiles: fileLocation.InputFiles || [],
  //           outputFiles: fileLocation.OutputFiles || [],
  //           errorFiles: fileLocation.ErrorLogs || [],
  //         });
  //       } else {
  //         // Handle the case where fileLocation is undefined or empty
  //         setFileDetails({
  //           inputFiles: [],
  //           outputFiles: [],
  //           errorFiles: [],
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch file details:", error);
  //   }
  // };
  const handleTaskClick = (id) => {
    setSelectedTask(id);
    // getFileDataById(id);
    fetchJobDetails(id);
  };

  const handleRefreshClick = (valu, jid) => {
    if (valu === "Refresh") {
      fetchJobDetails(jid);
    }
  };

  const handleExecuteJobClick = async () => {
    try {
      const { data } = await apiClient.post(
        `/job/executejob/${id}?userId=${UserID}`
      );
      if (data?.success) {
        fetchJobDetails(id);
        setSelectedTask(id); // Trigger re-render by updating selectedTask
        message.success("Job executed successfully!");
      } else {
        message.error("Failed to execute job.");
      }
    } catch (error) {
      message.error("Failed to execute job.");
    }
  };
  const handleSaveFiles = async () => {
    if (files?.length === 0) {
      message.error("Please upload at least one file.");
      return;
    }
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("multi-files", file?.originFileObj); // Correct field name here
    });
    try {
      const jobID = jobDetails?.JobTaskID;
      if (!jobID) {
        throw new Error("JobID is missing");
      }
      let resposnse = await apiClient.post(
        `/file/file/${jobID}?userId=${getUserId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (resposnse?.data?.status) {
        message.success("Files uploaded and saved successfully!");
        setFiles([]);
        //  getFileDataById(JobTaskID);
        fetchJobDetails(id);
        //  fetchJobDetails();
      } else {
        message.error("Failed to upload files. Please try again.");
      }
    } catch (error) {
      console.error("Failed to upload files:", error);
      message.error("Failed to upload files. Please try again.", error);
    }
  };

  const downloadFile = (fileName, jobId, getUserId, type) => {
    const url = `${AppConfig.API_URL}/download/${getUserId}/${jobId}/${type}/${fileName}`;
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loading ? (
        <div
          className="loader-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: "0",
            left: "0",
          }}
        >
          <Loader size={35} className="loader" />
        </div>
      ) : (
        <>
          <div className="payroll-tasks d-flex align-items-start justify-content-between">
            <Sidebar
              JobTaskID={jobDetails?.JobTaskID}
              jobTitle={jobDetails?.JobTitle}
              DCTaskDescription={jobDetails?.DCTaskDescription}
              DC3TaskID={jobDetails?.DC3TaskID}
              TaskStatusName={jobDetails?.TaskStatusName}
              InputFileUpload={jobDetails?.InputFileUpload}
              onTaskClick={handleTaskClick}
              onRefreshClick={handleRefreshClick}
              onExecuteJobClick={handleExecuteJobClick}
              viewErrorFile={viewErrorFile}
              errorFilesLength={fileDetails?.errorFiles?.length}
            />
            <div className="content w-100">
              <PageTitle
                mainTitle={
                  <div>
                    {jobDetails?.JobTitle?.toUpperCase()}
                    <br />
                    <small>
                      <>{jobDetails?.DCTaskDescription || ""}</>
                    </small>
                  </div>
                }
                breadcrumbItems={[
                  {
                    name: `${jobDetails?.JobTitle} / ${jobDetails?.DCTaskDescription}`,
                    path: `/payroll-process/${id}`,
                  },
                ]}
              />
              <Row
                gutter={[
                  { xs: 10, sm: 16, md: 24, lg: 30 },
                  { xs: 10, sm: 16, md: 24, lg: 30 },
                ]}
                justify="start"
                align="start"
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <div className="card bg-half-white p-4">
                    <h5 className="title mb-1"> Input - Upload Files </h5>
                    <p className="text-gray mb-3">Upload file to this task</p>
                    <div className="scroll-area">
                      <div className="drag-drop-field">
                        <MultiFileUpload
                          defaultFileList={[]}
                          listType={"picture"}
                          accept={"XLS,XLSX,CSV"}
                          onFilesSelected={(selectedFiles) =>
                            setFiles(selectedFiles)
                          }
                          fileList={files} // Control the file list
                          setFileList={setFiles} // Update the file list
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="font-6 text-gray">
                          Support format: XLS, XLSX, CSV
                        </span>
                        <span className="font-6 text-gray">
                          Maximum Size: 25MB
                        </span>
                      </div>

                      <div className="uploaded-file mt-3">
                        <h5 className="title"></h5>
                      </div>
                    </div>
                    <div className="action-button mt-3">
                      <ButtonBox
                        label="Save File"
                        type="primary"
                        rightArrowIcon
                        loading={loading}
                        onClick={handleSaveFiles}
                      />
                    </div>
                  </div>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <div className="card bg-half-white p-4">
                    <h5 className="title mb-1">Input Files</h5>
                    <p className="text-gray mb-3">Download the input files</p>
                    {fileDetails?.inputFiles?.length > 0
                      ? fileDetails.inputFiles.map((file) => (
                          <FileDownload
                            key={file.name}
                            fileName={file.name}
                            fileSize={file.size}
                            onClick={() =>
                              downloadFile(file.name, id, getUserId, "input")
                            }
                          />
                        ))
                      : "No File Found"}
                  </div>
                  <br />
                  <div className="card bg-half-white p-4">
                    <h5 className="title mb-1">Output File</h5>
                    <p className="text-gray mb-3">Download the Output files</p>
                    {fileDetails?.outputFiles?.length > 0
                      ? fileDetails.outputFiles.map((file) => (
                          <FileDownload
                            key={file.name}
                            fileName={file.name}
                            fileSize={file.size}
                            onClick={() =>
                              downloadFile(file.name, id, getUserId, "output")
                            }
                          />
                        ))
                      : "No File Found"}
                  </div>
                  {/* <div className="card bg-half-white p-4 mt-4">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <h5 className="title mb-0">Log File</h5>
                      {fileDetails?.errorFiles?.length > 0 ? (
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            viewErrorFile(id,getUserId, "error")
                          }
                        >
                          View Details
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    <p className="text-gray mb-3">Access Log file</p>
                    {fileDetails?.errorFiles?.length > 0
                      ? fileDetails.errorFiles.map((file) => (
                          <div key={file.name} className="file-item mb-2">
                            <FileDownload
                              fileName={file.name}
                              fileSize={file.size}
                              onClick={() =>
                                downloadFile(file.name, id, getUserId, "error")
                              }
                            />
                          </div>
                        ))
                      : "No File Found"}
                  </div> */}
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  );
}
