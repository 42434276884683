import React, { useState } from "react";
import { Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Trash2 } from "feather-icons-react";
import apiClient from "../Actions/apiClient";
import { message } from "antd";
const DeleteJob = ({ jobId, jobTitle, onDelete }) => {
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleOk = async () => {
    // onDelete(jobId);
    setVisible(false);
    try {
      const userData = JSON.parse(
        sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")
      );
      const UserID = userData?.UserID;
      const response = await apiClient.delete(
        `/job/job/${jobId}?userID=${UserID}`
      );
      if (response?.data?.success) {
        message.success("Job deleted successfully!");
        if (onDelete) onDelete();
        // setFileDetails(response?.data?.data);
      } else {
        message.error("Failed to delete job:", response?.data);
      }
    } catch (error) {
      message.error("Failed to delete job:", error);
      console.error("Failed to delete job:", error);
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <Button
        type="danger"
        className="d-flex align-items-center text-gray"
        onClick={showModal}
        style={{ marginLeft: "-11px", marginTop: "-9px" }}
      >
        <Trash2 size={16} />
        Delete
      </Button>
      <Modal
        title="Confirm Deletion"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes, delete it"
        cancelText="Cancel"
        icon={<ExclamationCircleOutlined />}
      >
        <p>
          Are you sure you want to permanently delete the job titled "
          <strong>{jobTitle}</strong>"?
        </p>
      </Modal>
    </>
  );
};

export default DeleteJob;
