import { Col, Row } from "antd";
import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import apiClient from "../../Actions/apiClient";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { ArrowUpRight } from "feather-icons-react/build/IconComponents";
import AssetPath from "../../AssetPath/AssetPath";
import "./Login.scss";
import { message } from "antd";
export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  const mobile = useMediaQuery({ maxWidth: 900 });

  useEffect(() => {
    if (sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")) {
      history.push("/home");
    }
  }, []);
  const onLogin = (values) => {
    apiClient.post("/user/login", values).then(({ data }) => {

      sessionStorage.removeItem("DC3_FILE_AUTOMATION_USER");
      if (data?.success) {
        // dispatch({ type: "GET_USER", payload: data });
        sessionStorage.setItem(
          "DC3_FILE_AUTOMATION_USER",
          JSON.stringify(data?.user)
        );
        history.push("/home");
      }
    });
  };
  return (
    <>
      <section className="login spacing bg-half-white position-relative overflow-hidden">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 position-relative z-index1"
            align="middle"
            justify="center"
          >
            {!mobile && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
                data-aos="fade-up"
              >
                <div className="bg-gradient-primary text-center card-design">
                  <h2 className="text-white">
                    Welcome back! Please sign in to your DC3 file automation
                    account
                  </h2>
                  <p className="text-white">
                    Upload multiple files to streamline and automate your
                    payroll process effortlessly.
                  </p>
                  <img
                    src={AssetPath.images.fileUpload}
                    alt="Login"
                    className="w-100 mt-2"
                  />
                </div>
              </Col>
            )}

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 10 }}
              data-aos="fade-up"
            >
              <div className="text-center">
                <img
                  src={AssetPath.brand.sandoz}
                  alt="Sandoz"
                  className="mb-2"
                />
                <h4 className="text-gray font-2 mb-3 text-capitalize">
                  DC3 File Automation
                </h4>
              </div>
              <div class="card-design bg-white" data-aos="fade-up">
                <h3>Sign In</h3>
                <p>Welcome back! Please enter your details</p>
                <div className="login-form">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    onSubmit={async (values) => {
                      onLogin(values);
                    }}
                  >
                    <Form>
                      <div className="mb-3">
                        <label className="text-gray mb-1">
                          Email <span className="text-error">*</span>
                        </label>
                        <Field
                          name="email"
                          required
                          placeholder="Email Address *"
                          type="email"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="text-gray mb-1">
                          Password <span className="text-error">*</span>
                        </label>

                        <Field
                          name="password"
                          required
                          placeholder="Password"
                          type="password"
                        />
                      </div>

                      <button
                        className="ant-btn ant-btn-primary btn-lg w-100"
                        type="submit"
                      >
                        Login
                        <ArrowUpRight size={16} className="ms-2" />
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <img
          src={AssetPath.shape.wave}
          alt="Sandoz"
          className="position-absolute left-0"
          style={{ width: "100%", bottom: "-40px" }}
        />
      </section>
    </>
  );
}
