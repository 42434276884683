import React, { useState, useEffect } from "react";
import "./Header.scss";
import { Bell, Plus } from "feather-icons-react/build/IconComponents";
import { useHistory, useLocation } from "react-router-dom";
import AssetPath from "../AssetPath/AssetPath";
import { Dropdown, Menu } from "antd";

export default function Header() {
  const navigation = useHistory();
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [getUserId, setUserId] = useState(null);
  const handleProfileClick = () => {
    setDropdownVisible(!isDropdownVisible); // Toggle dropdown visibility
  };
  useEffect(() => {
    const userData = JSON.parse(
      sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")
    );
    if (userData === null) {
      navigation.push("/");
      return;
    }
    const UserID = userData?.UserName;
    setUserId(UserID);
  }, []);
  const handleLogout = () => {
    sessionStorage.removeItem("DC3_FILE_AUTOMATION_USER"); // Clear user session
    navigation.push("/"); // Redirect to login page
  };

  const isLoginPage = location.pathname === "/";
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  if (isLoginPage) {
    return null;
  }

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };
  return (
    <div className="header d-flex align-items-center justify-content-between">
      <div
        className="brand font-2 font-weight-bold text-uppercase"
        style={{ cursor: "pointer" }}
        onClick={() => navigation.push("/home")}
      >
        <img src={AssetPath.brand.sandoz} alt="Sandoz" />
      </div>
      {!isLoginPage && (
        <div className="profile-action-items d-flex align-items-center justify-content-between">
          <span className="text-gray bell cursor-pointer">
            <Bell size={18} />
          </span>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            visible={isDropdownVisible}
            onVisibleChange={setDropdownVisible}
          >
            <span
              className="cursor-pointer profile text-white bg-primary rounded-circle d-flex align-items-center justify-content-center"
              onClick={handleProfileClick}
            >
              {getInitial(getUserId) || ""}
            </span>
          </Dropdown>
        </div>
      )}
    </div>
  );
}
