import React from "react";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <React.Fragment>
      <section className="footer bg-dark-blue">
        <p className="mb-0 font-4 text-white text-center">
          Copyright © {currentYear} McBitss. All Rights Reserved
        </p>
      </section>
    </React.Fragment>
  );
}
