import React, { useState } from "react";
import { Layout, Menu } from "antd";
import AssetPath from "../../AssetPath/AssetPath";

const { Sider } = Layout;

export default function SiderMenu({
  theme,
  defaultSelectedKeys,
  mode,
  items,
  width,
  collapsedWidth,
}) {
  const [iscollapsed, setIsCollapsed] = useState(false);

  return (
    <Sider
      collapsible
      collapsed={iscollapsed}
      onCollapse={(value) => setIsCollapsed(value)}
      width={width}
      collapsedWidth={collapsedWidth}
    >
      <div className="logo-vertical d-flex align-items-center justify-content-center">
        <img src={AssetPath.brand.sandozFavicon} alt="Sandoz" />
      </div>
      <Menu
        theme={theme}
        defaultSelectedKeys={defaultSelectedKeys}
        mode={mode}
        items={items}
      />
    </Sider>
  );
}
