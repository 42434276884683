import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
const { Dragger } = Upload;

const MultiFileUpload = ({
  className,
  style,
  listType,
  defaultFileList,
  accept,
  onFilesSelected,
  fileList, // Add this prop to control the file list
  setFileList, // Add this prop to update the file list
}) => {
  const props = {
    name: "file",
    multiple: true,
    beforeUpload(file) {
      const isExcel =
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "text/csv";
      if (!isExcel) {
        message.error("You can only upload XLS, XLSX, or CSV files!");
        return Upload.LIST_IGNORE;
      }
      return isExcel;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
       // console.log(info.file, info.fileList);
      }
      if (onFilesSelected) {
        onFilesSelected(info.fileList);
      }
      setFileList(info.fileList); // Update the file list state
    },
    onDrop(e) {
    //  console.log("Dropped files", e.dataTransfer.files);
    },
    className: className,
    listType: listType,
    defaultFileList: [...defaultFileList],
    style: style,
    accept: accept,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    fileList: fileList, // Control the file list using state
  };

  return (
    <>
      <Dragger {...props} className="multi-file-upload">
        <p className="ant-upload-drag-icon">
          <img src={AssetPath.icons.uploadFileImage} alt="File Upload" />
        </p>
        <h6 className="font-weight-normal text-gray">
          Drag and drop file here to upload or Choose file
        </h6>
      </Dragger>
    </>
  );
};

export default MultiFileUpload;
