import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../screens/Login/Login";
import Layout from "../Layout/Layout";
import PageNotFound from "../screens/PageNotFound/PageNotFound";
import PayrollProcess from "../screens/PayrollProcess/PayrollProcess";
import Home from "../LandingPage/Home";
import CreateJob from "../screens/CreateJob/CreateJob";
import CreateOrUpdateTask from "../screens/CreateUpdateTask/CreateOrUpdateTask";
import TaskHome from "../screens/CreateUpdateTask/TaskHome";
import ErrorLogs from "../screens/ErrorLogs/ErrorLogs";
class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Login} />
          <Route
            exact
            path={`/payroll-process/:id`}
            component={PayrollProcess}
          />
          <Route exact path={`/create-task`} component={CreateOrUpdateTask} />
          <Route path={"/update-task/:jobId"} component={CreateOrUpdateTask} />
          <Route exact path={`/create-job`} component={CreateJob} />
          <Route exact path={`/task-home`} component={TaskHome} />
          <Route path={"/update-job/:jobId"} component={CreateJob} />
          <Route path={`/home`} component={Home} />
          <Route path={`/logs`} component={ErrorLogs} />
          {/* page not found */}
          <Route path={`*`} component={PageNotFound} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
