import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import apiClient from "../../Actions/apiClient";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import "./CreateTask.scss";
import { ArrowUpRight } from "feather-icons-react";

// Validation schema
const validationSchema = Yup.object().shape({
  DCTaskDescription: Yup.string().required("Task description is required"),
  Country: Yup.string().required("Country is required"),
  DC3ProjectName: Yup.string(),
});

export default function CreateTaskForm() {
  const history = useHistory();
  const [getUserId, setUserId] = useState(null);

  const createNewTask = async (values, { setSubmitting, setStatus }) => {
    const payload = {
      ...values,
      userId: getUserId,
    };

    try {
      const { data } = await apiClient.post("/task/create", payload);

      if (data?.success) {
        message.success("Task created successfully!");

        setTimeout(() => {
          goBackHome();
        }, 1200); // 2000 milliseconds = 2 seconds
      } else {
        message.error("Failed to create task:");
        setStatus({ success: false, message: "Failed to create task" });
      }
    } catch (error) {
      message.error("Failed to create task:", error);
      console.error("Failed to create task:", error);
      setStatus({ success: false, message: "Failed to create task" });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const userData = JSON.parse(
      sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")
    );
    if (userData === null) {
      history.push("/");
      return;
    }
    const UserID = userData?.UserID;
    setUserId(UserID);
  }, []);
  const goBackHome = () => {
    history.push(`/task-home`);
  };
  return (
    <div className="card p-5 border mt-3">
      <Formik
        initialValues={{
          DCTaskDescription: "",
          Country: "",
          DC3ProjectName: "",
        }}
        validationSchema={validationSchema}
        onSubmit={createNewTask}
      >
        {({ errors, touched, isSubmitting, status }) => (
          <Form>
            <div className="mb-3">
              <Field
                type="text"
                name="DCTaskDescription"
                placeholder="Enter task description *"
                className="form-control"
              />
              {touched.DCTaskDescription && errors.DCTaskDescription ? (
                <div className="text-danger">{errors.DCTaskDescription}</div>
              ) : null}
            </div>

            <div className="mb-3">
              <Field
                type="text"
                name="Country"
                placeholder="Enter country *"
                className="form-control"
              />
              {touched.Country && errors.Country ? (
                <div className="text-danger">{errors.Country}</div>
              ) : null}
            </div>

            <div className="mb-3">
              <Field
                type="text"
                name="DC3ProjectName"
                placeholder="Enter project name "
                className="form-control"
              />
              {touched.DC3ProjectName && errors.DC3ProjectName ? (
                <div className="text-danger">{errors.DC3ProjectName}</div>
              ) : null}
            </div>

            <div className="mt-4">
              <button
                className="ant-btn ant-btn-primary btn-lg"
                type="submit"
                label="Create Task"
                disabled={isSubmitting}
              >
                Create Task
                <ArrowUpRight size={16} className="ms-2" />
              </button>
              {status && status.message && (
                <div
                  className={`text-${
                    status.success ? "success" : "danger"
                  } mt-2`}
                >
                  {status.message}
                </div>
              )}{" "}
              &nbsp;&nbsp;{" "}
              <button
                className="ant-btn ant-btn btn-lg"
                type="button"
                onClick={() => goBackHome()}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
