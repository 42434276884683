import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import CreateJobForm from "./CreateJobForm";
import UpdateJobForm from "./UpdateJobForm";

export default function CreateOrUpdateJob() {
  const { jobId } = useParams();
  return (
    <section className="space-around">
      <Row
        gutter={[
          { xs: 0, sm: 16, md: 24, lg: 32 },
          { xs: 0, sm: 16, md: 24, lg: 20 },
        ]}
        align="center"
        justify="middle"
      >
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 17 }}
          lg={{ span: 15 }}
        >
          <PageTitle
            breadcrumbItems={[
              {
                name: jobId ? "Update Job" : "Create New Job",
                path: jobId ? `/update-job/${jobId}` : "/create-job",
              },
            ]}
          />
          <h3>{jobId ? "Update Job" : "Create New Job"}</h3>
          {jobId ? <UpdateJobForm jobId={jobId} /> : <CreateJobForm />}
        </Col>
      </Row>
    </section>
  );
}
