import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import CreateTaskForm from "./CreateTaskForm";
import UpdateTaskForm from "./UpdateTaskForm";

export default function CreateOrUpdateTask() {
  const params = useParams();
  const jobId = params?.jobId;
  return (
    <section className="space-around">
      <Row
        gutter={[
          { xs: 0, sm: 16, md: 24, lg: 32 },
          { xs: 0, sm: 16, md: 24, lg: 20 },
        ]}
        align="center"
        justify="middle"
      >
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 17 }}
          lg={{ span: 15 }}
        >
          <PageTitle
            breadcrumbItems={[
              {
                name: jobId ? "Update Task" : "Create New Task",
                path: jobId ? `/update-job/${jobId}` : "/create-job",
              },
            ]}
          />
          <h3>{jobId ? "Update Task" : "Create New Task"}</h3>
          {jobId ? <UpdateTaskForm jobId={jobId} /> : <CreateTaskForm />}
        </Col>
      </Row>
    </section>
  );
}
