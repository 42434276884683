import React from "react";
import { Edit, MoreVertical, Loader } from "feather-icons-react";
import { Dropdown, Menu } from "antd";
import { useHistory } from "react-router-dom";
import DeleteTask from "./DeleteTask";
import ShowTable from "../../Components/ShowTable/ShowTable";
import { FileText, Folder } from "feather-icons-react/build/IconComponents";

const TaskList = ({ tasks, onDelete, loading }) => {
  const history = useHistory();

  const handleEdit = (id) => {
    history.push(`/update-task/${id}`);
  };

  const dropdownItems = (task) => (
    <Menu>
      <Menu.Item key="0">
        <span
          className="d-flex align-items-center text-gray"
          onClick={() => handleEdit(task.DC3TaskID)}
        >
          <Edit size={16} className="me-2" />
          Edit
        </span>
      </Menu.Item>
      <Menu.Item key="1">
        <DeleteTask
          taskId={task.DC3TaskID}
          taskTitle={task.DCTaskDescription}
          onDelete={onDelete}
        />
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Task Title",
      dataIndex: "DCTaskDescription",
      render: (text, record) => (
        <>
          <FileText size={17} className="text-primary me-2" />
          {record.DCTaskDescription}
        </>
      ),
    },
    {
      title: "File Path",
      dataIndex: "DC3ProjectName",
      render: (text, record) => record.DC3ProjectName || "_",
    },
    {
      title: "Country",
      dataIndex: "Country",
      render: (text, record) => record.Country,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, task) => (
        <Dropdown overlay={dropdownItems(task)} trigger={["click"]}>
          {/* Ensure there is only a single child element */}
          <span className="link rounded">
            <MoreVertical size={18} className="cursor-pointer" />
          </span>
        </Dropdown>
      ),
    },
  ];

  return loading ? (
    <div
      className="loader-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
      }}
    >
      <Loader size={35} className="loader" />
    </div>
  ) : (
    <div className="jobs-list">
      {tasks && tasks.length > 0 ? (
        <ShowTable columns={columns} dataSource={tasks} />
      ) : (
        <p>No tasks found</p>
      )}
    </div>
  );
};

export default TaskList;
