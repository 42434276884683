import axios from "axios";
import AppConfig from "../config";
import { message } from "antd";

const apiClient = axios.create({
  baseURL: `${AppConfig.API_URL}/`,
});

apiClient.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        Authorization:
          config.headers.Authorization ||
          `Bearer ${sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")}`,
      },
    };
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async ({ response }) => {
    if (response) {
      const { data, status } = response;

      if (status >= 400 && status < 500) {
        if (data.errors && Object.keys(data.errors).length > 0) {
          errorsParser(data.errors);
        } else if (data.message) {
          message.error(data.message);
        }

        return Promise.resolve({ status, ...data });
      }

      if (status >= 500) {
        errorsParser(data.errors);

        return Promise.resolve({ status, ...data });
      }

      return Promise.resolve({ status, ...data });
    }
  }
);

const errorsParser = (errors) => {
  if (errors) {
    if (typeof errors === "string") {
      message.error(errors);
    } else {
      Object.values(errors).forEach((err) => {
        message.error(err);
      });
    }
  }
};

export default apiClient;
