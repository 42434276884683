import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../Actions/apiClient";
import JobsList from "./JobsList";
import JobsGrid from "./JobsGrid";
import { Tabs } from "antd";
import { AlignCenter, Grid } from "feather-icons-react";
import ButtonBox from "../Components/ButtonBox/ButtonBox";
import PageTitle from "../Components/PageTitle/PageTitle";

export default function Home() {
  const history = useHistory();
  const [jobs, setJobs] = useState([]);
  const [view, setView] = useState("list");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // Fetch jobs data
  useEffect(() => {
    fetchJobs();
  }, []);
  useEffect(() => {
    handleSearch(searchTerm); // Apply search filter whenever jobs or searchTerm changes
  }, [jobs, searchTerm]);
  function onDelete() {
    fetchJobs();
  }
  function onJobClone() {
    fetchJobs();
  }
  const onSend = (values) => {
    // apiClient.post("contactus/add", values).then(({ data }) => {
    //   if (data?.result) {
    //     history.push("/");
    //   }
    // });
  };
  const fetchJobs = async () => {
    try {
      const userData = JSON.parse(
        sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")
      );
      if (userData === null) {
        history.push("/");
        return;
      }
      const UserID = userData?.UserID;
      const { data } = await apiClient.get(`/job/jobs/${UserID}`);
      if (data?.success) {
        setJobs(data?.jobs); // Adjust the response structure if needed
        setFilteredJobs(data?.jobs);
      }
    } catch (error) {
      console.error("Failed to fetch jobs:", error);
    }
  };

  const handleList = () => {
    setView("list");
  };
  const handleGrid = () => {
    setView("grid");
  };
  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredJobs(jobs); // Reset to the full list when the search is empty
    } else {
      const filtered = jobs?.filter((job) => {
        const JobTitle = job?.JobTitle || "";

        return JobTitle.toLowerCase().includes(value.toLowerCase());
      });

      setFilteredJobs(filtered);
    }
  };
  return (
    <>
      <section className="space-around create-jobs">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="title">Jobs</h3>
          <PageTitle
            breadcrumbItems={[
              {
                name: "Jobs",
                path: "/home",
              },
            ]}
          />
        </div>

        <div className="all-jobs-section">
          <Tabs
            defaultActiveKey="all-jobs"
            tabBarExtraContent={{
              right: (
                <div className="extra-action-icons d-flex align-items-center justify-content-between">
                  <div className="global-search me-2">
                    <input
                      type="text"
                      placeholder="Search all jobs"
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                  <ButtonBox
                    type="default"
                    onClick={handleList}
                    className={`view-icon ${view === "list" ? "active" : ""}`}
                  >
                    <AlignCenter size={16} />
                  </ButtonBox>
                  <ButtonBox
                    type="default"
                    onClick={handleGrid}
                    className={`view-icon ml-2 ${
                      view === "grid" ? "active" : ""
                    }`}
                  >
                    <Grid size={16} />
                  </ButtonBox>
                </div>
              ),
            }}
          >
            <Tabs.TabPane tab="All Jobs" key="all-jobs">
              {view === "list" ? (
                <JobsList
                  jobs={filteredJobs}
                  onDelete={onDelete}
                  onJobClone={onJobClone}
                /> // Pass jobs data to JobsList
              ) : (
                <JobsGrid
                  jobs={filteredJobs}
                  onDelete={onDelete}
                  onJobClone={onJobClone}
                /> // Pass jobs data to JobsGrid
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Recently Created Jobs" key="recent-jobs">
              <RecentlyCreatedJobs />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </section>
    </>
  );
}

function RecentlyCreatedJobs() {
  return (
    <div className="recently-created-jobs">
      <h3>Recently Created Jobs</h3>
      <p>No recent jobs found. Start by creating a new job.</p>
    </div>
  );
}
