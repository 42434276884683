import React, { useEffect, useState } from "react";
import { Field, Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import apiClient from "../../Actions/apiClient";
import { useHistory, useParams } from "react-router-dom";
import { Plus, ArrowUpRight } from "feather-icons-react";
import { Trash } from "feather-icons-react/build/IconComponents";
import "./CreateJob.scss";
import { X, Loader } from "feather-icons-react/";
import { message } from "antd";

// Validation schema
const validationSchema = Yup.object().shape({
  jobTitle: Yup.string().required("Job title is required"),
  DC3TaskID: Yup.string().required("Select a payroll task"),
});

export default function UpdateJobForm() {
  const history = useHistory();
  const { jobId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [initialValues, setInitialValues] = useState({
    jobTitle: "",
    DC3TaskID: "",
  });
  const handleCancel = () => {
    history.goBack(); // Go back to the previous page
  };
  useEffect(() => {
    const fetchTasksAndUser = async () => {
      try {
        const [tasksResponse, userResponse] = await Promise.all([
          apiClient.get("/task/dc3/tasks"),
          apiClient.get("/user/users"),
        ]);
        setTasks(tasksResponse.data);
        setUserId(userResponse.data?.data[0]?.UserID);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    const fetchJobDetails = async () => {
      try {
        const response = await apiClient.get(`/job/job/${jobId}`);
        if (response?.data?.success) {
          const jobDetails = response.data.jobDetails;
          setInitialValues({
            jobTitle: jobDetails.JobTitle,
            DC3TaskID: jobDetails.DC3TaskID, // Adjust according to your select options
          });
        }
      } catch (error) {
        console.error("Failed to fetch job details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTasksAndUser();
    fetchJobDetails();
  }, [jobId]);

  const deleteTask = async (taskId, jobId) => {
    try {
      const { UserID } = JSON.parse(
        sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")
      );
      // const { UserID } = 1
      let resDel = await apiClient.delete(
        `/job/tasks/${taskId}?jobId=${jobId}&userId=${UserID}`
      );
      // Optionally, remove the task from the state if the API call is successful
      //setTasks(prevTasks => prevTasks.filter(task => task.TaskID !== taskId));
      if (resDel?.data?.success) {
        message.success("Task deleted successfully!");
      } else {
        message.error("Failed to delete task.");
      }
    } catch (error) {
      message.error("Failed to delete task.");
      console.error("Failed to delete task:", error);
    }
  };
  const updateJob = async (values, { setSubmitting, setStatus }) => {
    const payload = {
      jobTitle: values.jobTitle,
      userId: userId,
      DC3TaskID: values.payroll,
    };

    try {
      const { UserID } = JSON.parse(
        sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")
      );
      const { data } = await apiClient.put(
        `/job/job/${jobId}?userId=${UserID}`,
        payload
      );
      if (data?.success) {
        setTimeout(() => {
          // Code to be executed after 2 seconds
          history.push({
            pathname: `/payroll-process/${jobId}`,
            state: { UserID },
          });
        }, 1200); // 2000 milliseconds = 2 seconds
        message.success("Job updated successfully!");
      } else {
        message.error("Failed to update job:");
        setStatus({ success: false, message: "Failed to update job" });
      }
    } catch (error) {
      message.error("Failed to update job:", error);
      console.error("Failed to update job:", error);
      setStatus({ success: false, message: "Failed to update job" });
    } finally {
      setSubmitting(false);
    }
  };

  return loading ? (
    <div
      className="loader-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
      }}
    >
      <Loader size={35} className="loader" />
    </div>
  ) : (
    <div className="card p-5 border mt-3">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={updateJob}
      >
        {({ values, errors, touched, isSubmitting, status }) => (
          <Form>
            <div className="d-flex flex-column align-items-center mb-3">
              <div className="field w-100" style={{ flex: 1 }}>
                <Field
                  type="text"
                  name="jobTitle"
                  placeholder="Enter a new job title *"
                  className="form-control"
                />
                {touched.jobTitle && errors.jobTitle ? (
                  <div className="text-danger">{errors.jobTitle}</div>
                ) : null}
                <span className="d-block mt-2 font-5 text-gray font-weight-normal mb-2">
                  Note: Based on the title folder name will be created
                </span>
              </div>
              <div className="field w-100 mt-2" Update Job>
                <Field as="select" name={`DC3TaskID`} className="form-control">
                  <option value="" disabled>
                    Select Payroll
                  </option>
                  {tasks.map((task) => (
                    <option key={task.DC3TaskID} value={task.DC3TaskID}>
                      {task.DCTaskDescription}
                    </option>
                  ))}
                </Field>
                {touched.DC3TaskID && errors.DC3TaskID ? (
                  <div className="text-danger">{errors.DC3TaskID}</div>
                ) : null}
              </div>
            </div>
            <div className="mt-4">
              <button
                className="ant-btn ant-btn-primary btn-lg"
                type="submit"
                label="Update Job"
                disabled={isSubmitting}
              >
                Update Job
                <ArrowUpRight size={16} className="ms-2" />
              </button>
              {status && status.message && (
                <div
                  className={`text-${
                    status.success ? "success" : "danger"
                  } mt-2`}
                >
                  {status.message}
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
