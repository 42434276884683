import React, { useEffect, useState } from "react";
import ShowTable from "../../Components/ShowTable/ShowTable";
import apiClient from "../../Actions/apiClient";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { X, Loader } from "feather-icons-react/";
import PageTitle from "../../Components/PageTitle/PageTitle";
export default function ErrorLogs() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { id, jobTitle, DCTaskDescription } = location.state || {}; // Only getting `id` from location.state

  // Retrieve UserID from session storage
  const userData = JSON.parse(
    sessionStorage.getItem("DC3_FILE_AUTOMATION_USER")
  );
  const UserID = userData?.UserID;

  const [columns, setColumns] = useState([
    {
      title: "#",
      dataIndex: "Index",
      key: "Index",
    },
    {
      title: "Sequence Number",
      dataIndex: "SequenceNumber",
      key: "SequenceNumber",
    },
    {
      title: "Date and Time",
      dataIndex: "DateTime",
      key: "DateTime",
      render: (DateTime) => new Date(DateTime).toLocaleString(), // Format the date
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Message",
      dataIndex: "Message",
      key: "Message",
    },
  ]);

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle null or undefined date strings
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "" : date.toLocaleString(); // Check for valid date and format
  };

  useEffect(() => {
    const fetchErrorLogs = async () => {
      try {
        // Choose the appropriate endpoint based on whether `id` is present
        let endpoint;
        if (id !== undefined && UserID) {
          endpoint = `/file/errorLogs/${id}?userId=${UserID}`;
        }
        if (UserID && id === undefined)
          endpoint = `/file/allErrorLogs/${UserID}`;

        const { data } = await apiClient.get(endpoint);

        if (data?.success && data.data.length > 0) {
          const logs = data.data.map((log, index) => ({
            ...log,
            DateTime: formatDate(log.DateTime), // Format date for each log
            Index: index + 1,
          }));
          setDataSource(logs);
          setColumns(generateColumns(logs));
        } else {
          message.info("No error logs found.");
          setDataSource([]);
        }
      } catch (error) {
        message.error("Error fetching error logs: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchErrorLogs();
  }, [id, UserID]); // Dependencies

  const generateColumns = (logs) => {
    const jobIDFilters = [
      ...new Set(logs.map((log) => log.SequenceNumber)),
    ].map((value) => ({ text: value, value }));

    const statusFilters = [...new Set(logs.map((log) => log.Status))].map(
      (value) => ({ text: value, value })
    );

    return [
      {
        title: "#",
        dataIndex: "Index",
        key: "Index",
      },
      {
        title: "SequenceNumber",
        dataIndex: "SequenceNumber",
        key: "SequenceNumber",
        filters: jobIDFilters,
        onFilter: (value, record) => record.SequenceNumber === value,
      },
      {
        title: "Date and Time",
        dataIndex: "DateTime",
        key: "DateTime",
        sorter: (a, b) => new Date(a.DateTime) - new Date(b.DateTime),
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        filters: statusFilters,
        onFilter: (value, record) => record.Status === value,
      },
      {
        title: "Message",
        dataIndex: "Message",
        key: "Message",
      },
    ];
  };

  const onChange = (pagination, filters, sorter, extra) => {
    //  console.log("params", pagination, filters, sorter, extra);
  };

  return loading ? (
    <div
      className="loader-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
      }}
    >
      <Loader size={35} className="loader" />
    </div>
  ) : (
    <>
      {jobTitle && DCTaskDescription ? (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2vh",
            textAlign: "center", // Ensures text is centered within the container
          }}
        >
          <h5
            style={{
              margin: 0,
              // You can add additional styles here if needed
            }}
          >
            {jobTitle}
          </h5>
          <p
            style={{
              color: "gray",
              // You can adjust or add other styles as needed
            }}
          >
            {DCTaskDescription}
          </p>
        </span>
      ) : (
        <></>
      )}

      <section className="space-around create-tasks">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="title">Logs</h3>
          <PageTitle
            breadcrumbItems={[
              {
                name: "Logs",
                path: "/logs",
              },
            ]}
          />
        </div>
        <ShowTable
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          onChange={onChange}
        />
      </section>
    </>
  );
}
