import React, { useState, useEffect } from "react";
import { Plus, Folder, List, RefreshCcw } from "feather-icons-react";
import "./Sidebar.scss";
import ButtonBox from "../Components/ButtonBox/ButtonBox";
import { useHistory } from "react-router-dom";

export default function Sidebar({
  JobTaskID,
  jobTitle,
  DCTaskDescription,
  DC3TaskID,
  TaskStatusName,
  InputFileUpload,
  tasks,
  onTaskClick,
  onRefreshClick,
  onExecuteJobClick,
  viewErrorFile,
  errorFilesLength,
}) {
  const history = useHistory();
  const [activeTask, setActiveTask] = useState(DC3TaskID);
  const [showExecuteBtn, setShowExecuteBtn] = useState(false);
  const [activeTaskStatus, setActiveTaskStatus] = useState(TaskStatusName);

  const handleJobUpdate = (JobTaskID) => {
    history.push(`/update-job/${JobTaskID}`);
  };
  // useEffect(() => {
  //   if (tasks?.length > 0) {
  //     setActiveTask(tasks[0]?.DC3TaskID);
  //     setActiveTaskStatus(tasks[0]?.TaskStatusName);
  //   }
  // }, [tasks]);
  useEffect(() => {
    setActiveTaskStatus(TaskStatusName);
  }, [TaskStatusName]);
  useEffect(() => {
    setShowExecuteBtn(InputFileUpload);
  }, [InputFileUpload]);

  const getStatusClassName = (status) => {
    switch (status) {
      case "NEW":
        return "released";
      case "IN PROGRESS":
      case "Processing":
        return "executing";
      case "COMPLETED":
        return "completed";
      case "Error":
        return "error";
      default:
        return "";
    }
  };

  const handleTaskClick = (taskID) => {
    setActiveTask(taskID);
    onTaskClick(taskID);
  };

  return (
    <div className="sidebar d-flex flex-column align-items-center justify-content-between">
      <div className="title-and-navigations w-100">
        <div className="title-and-action d-flex align-items-center justify-content-between">
          <span className="font-weight-medium me-2 font-6 text-ellipse1">
            {jobTitle && jobTitle.toUpperCase()}
          </span>
          <span
            className="add-btn bg-primary text-white cursor-pointer rounded d-flex align-items-center"
            onClick={() => handleJobUpdate(JobTaskID)}
            title="Update Job"
          >
            <Plus size={16} />
          </span>
        </div>
        <ul className="payroll-task-navigations list-unstyled p-0 m-0">
          <li
            key={DC3TaskID}
            onClick={() => handleTaskClick(DC3TaskID)}
            className={`cursor-pointer d-flex align-items-center justify-content-start ${
              activeTask === DC3TaskID ? "active" : ""
            }`}
          >
            <Folder size={16} className="me-2" />
            {DCTaskDescription}
          </li>
          {errorFilesLength > 0 ? (
            <>
              {" "}
              <hr></hr>
              <li
                key={DC3TaskID}
                onClick={() =>
                  viewErrorFile(JobTaskID, jobTitle, DCTaskDescription)
                }
                className={`cursor-pointer d-flex align-items-center justify-content-start`}
              >
                <List size={16} className="me-2" />
                {`View Log List`}
              </li>
            </>
          ) : (
            <></>
          )}

          {/* {tasks?.map((task) => (
            <li
              key={ DC3TaskID}
              onClick={() => handleTaskClick( DC3TaskID)}
              className={`cursor-pointer d-flex align-items-center justify-content-start ${
                activeTask ===  DC3TaskID ? "active" : ""
              }`}
            >
              <Folder size={16} className="me-2" />
              { DCTaskDescription}
            </li>
          ))} */}
        </ul>
      </div>
      <div className="button-and-status w-100">
        {activeTaskStatus === "NEW" && showExecuteBtn && (
          <div className="execute-tasks element-spacing">
            <span className="text-gray d-block font-6 mb-2">
              Execute All the Tasks configured
            </span>
            <ButtonBox
              label="Execute Job"
              rightArrowIcon
              type="primary"
              className="w-100"
              onClick={() => onExecuteJobClick(JobTaskID)}
            />
          </div>
        )}
        <div className="border-top element-spacing pt-3 mt-2 status-refresh d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-between">
            <div
              className={`status-circle rounded-circle ${getStatusClassName(
                activeTaskStatus
              )} me-2`}
            />
            <span className={"text-gray"}>{activeTaskStatus}</span>
          </div>
          <span
            className="add-btn bg-secondary cursor-pointer rounded d-flex align-items-center"
            title="Refresh"
            onClick={() => onRefreshClick("Refresh", JobTaskID)}
          >
            <RefreshCcw size={16} />
          </span>
        </div>
      </div>
    </div>
  );
}
